/** ***********************************************************************************
 * Color design tokens
 *********************************************************************************** */

:root {

  /** GAINSBORO: #dcdcdc */
  --cn-global--palette-gainsboro--100: rgba(220, 220, 220, 1);
  --cn-global--palette-gainsboro: var(--cn-global--palette-gainsboro--100);
}
