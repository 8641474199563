/** ***********************************************************************************
 * Typography E-commerce design tokens
 *********************************************************************************** */

@use "../../fonts/PPNeueMontreal.scss";
@use "../../fonts/EuclidCircularB.scss";
@use "../../utilities.scss" as *;

:root {

  /**
    * @tokens Article font sizes
    * @presenter FontSize
    */

  --cn-global--FontSize-label--xxs: .625rem;  /* Label / Extra Extra Small: 10px */
  --cn-global--FontSize-label--xs:  .875rem;  /* Label / Small: 14px */
  --cn-global--FontSize-label--sm: #{pxToRem(16px)};
  --cn-global--FontSize-label--md: #{pxToRem(18px)};
  --cn-global--FontSize-label--lg: #{pxToRem(23px)};
  --cn-global--FontSize-label--xl: #{pxToRem(30px)};

  --cn-global--FontSize-paragraph--md:  #{pxToRem(16px)};
  --cn-global--FontSize-paragraph--lg:  #{pxToRem(18px)};

  --cn-global--FontSize-title--sm: #{pxToRem(18px)};
  --cn-global--FontSize-title--md: #{pxToRem(20px)};
  --cn-global--FontSize-title--lg: #{pxToRem(26px)};
  --cn-global--FontSize-title--xl: #{pxToRem(45px)};
  --cn-global--FontSize-title--2xl: #{pxToRem(50px)};


  /**
    * @tokens Text styles
    */

  --cn-global--Font-label--xxs-medium: var(--cn-global--FontWeight-medium) var(--cn-global--FontSize-label--xxs) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xs-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-label--xs) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xs-book: var(--cn-global--FontWeight-book-regular) var(--cn-global--FontSize-label--xs) var(--cn-global--FontFamily-book);
  --cn-global--Font-label--xs-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--xs) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--sm-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--sm-medium: var(--cn-global--FontWeight-medium) var(--cn-global--FontSize-label--sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-medium: var(--cn-global--FontWeight-medium) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-bold: var(--cn-global--FontWeight-bold) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--lg-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--lg) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xl-bold: var(--cn-global--FontWeight-bold) var(--cn-global--FontSize-label--xl) var(--cn-global--FontFamily-default);

  --cn-global--Font-paragraph--md-medium: var(--cn-global--FontWeight-medium) var(--cn-global--FontSize-paragraph--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-paragraph--lg-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-paragraph--lg) var(--cn-global--FontFamily-default);

  --cn-global--Font-title--sm-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-title--sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--sm-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--md-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--lg-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--lg) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--xl-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--xl) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--2xl-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--2xl) var(--cn-global--FontFamily-default);


  /**
  * @tokens Line heights
  * @presenter LineHeight
  */

  --cn-global--LineHeight-xs: #{pxToRem(24px)};
  --cn-global--LineHeight-md: #{pxToRem(30px)};
  --cn-global--LineHeight-lg: #{pxToRem(50px)};
  --cn-global--LineHeight-xl: #{pxToRem(54px)};

}
