/** ***********************************************************************************
 * Layout design tokens
 *********************************************************************************** */

 @use "sass:map";
@use "../../shared/variables.module.scss" as *;
@use "../../utilities.scss" as *;

:root {
  /**
   * @tokens Breakpoints
   */

  --cn-global--breakpoints--sm: map.get($grid-breakpoints, sm);
  --cn-global--breakpoints--md: map.get($grid-breakpoints, md);
  --cn-global--breakpoints--lg: map.get($grid-breakpoints, lg);
  --cn-global--breakpoints--xl: map.get($grid-breakpoints, xl);
  --cn-global--breakpoints--xxl: map.get($grid-breakpoints, xxl);


  /**
   * Content grid
   */

  /** Column count */
  --cn-global--content-grid--sm--columncount: 2;
  --cn-global--content-grid--md--columncount: 12;
  --cn-global--content-grid--columncount: var(--cn-global--content-grid--sm--columncount);

  /** Column size */
  --cn-global--content-grid--xs--columnsize: 9.5vw;     /* 152px */
  --cn-global--content-grid--sm--columnsize: 2.375rem;  /* 38px */
  --cn-global--content-grid--md--columnsize: 3.188rem;  /* 51px */
  --cn-global--content-grid--lg--columnsize: 4.5rem;    /* 72px */
  /* --cn-global--content-grid--columnsize: var(--cn-global--content-grid--xs--columnsize); */

  /** Gutter size */
  --cn-global--content-grid--xs--guttersize: #{pxToRem(16px)};
  --cn-global--content-grid--sm--guttersize: #{pxToRem(24px)};
  --cn-global--content-grid--md--guttersize: #{pxToRem(22px)};
  --cn-global--content-grid--lg--guttersize: #{pxToRem(29px)};
  --cn-global--content-grid--xl--guttersize: #{pxToRem(36px)};
  --cn-global--content-grid--xxl--guttersize: #{pxToRem(40px)};
  --cn-global--content-grid--guttersize: var(--cn-global--content-grid--xs--guttersize);

  /** Offset size */
  --cn-global--content-grid--xs--offsetsize: #{pxToRem(16px)};
  --cn-global--content-grid--sm--offsetsize: #{pxToRem(26px)};
  --cn-global--content-grid--md--offsetsize: #{pxToRem(38px)};
  --cn-global--content-grid--lg--offsetsize: #{pxToRem(48px)};
  --cn-global--content-grid--xl--offsetsize: #{pxToRem(60px)};
  --cn-global--content-grid--xxl--offsetsize: #{pxToRem(68px)};
  --cn-global--content-grid--offsetsize: var(--cn-global--content-grid--xs--offsetsize);

  --cn-global--content-grid--GridTemplateColumns: repeat(var(--cn-global--content-grid--columncount), 1fr);
}

/* sm: 600px */
@media all and (min-width: map.get($grid-breakpoints, sm)) {
  :root {
    --cn-global--content-grid--columncount: var(--cn-global--content-grid--sm--columncount);
    /* --cn-global--content-grid--columnsize: var(--cn-global--content-grid--sm--columnsize); */
    --cn-global--content-grid--guttersize: var(--cn-global--content-grid--sm--guttersize);
    --cn-global--content-grid--offsetsize: var(--cn-global--content-grid--sm--offsetsize);
  }
}

/* md: 768px */
@media all and (min-width: map.get($grid-breakpoints, md)) {
  :root {
    --cn-global--content-grid--columncount: var(--cn-global--content-grid--md--columncount);
    /* --cn-global--content-grid--columnsize: var(--cn-global--content-grid--md--columnsize); */
    --cn-global--content-grid--guttersize: var(--cn-global--content-grid--md--guttersize);
    --cn-global--content-grid--offsetsize: var(--cn-global--content-grid--md--offsetsize);
  }
}

/* lg: 1024px */
@media all and (min-width: map.get($grid-breakpoints, lg)) {
  :root {
    /* --cn-global--content-grid--columnsize: var(--cn-global--content-grid--lg--columnsize); */
    --cn-global--content-grid--guttersize: var(--cn-global--content-grid--lg--guttersize);
    --cn-global--content-grid--offsetsize: var(--cn-global--content-grid--lg--offsetsize);
  }
}

/* xl: 1280px */
@media all and (min-width: map.get($grid-breakpoints, xl)) {
  :root {
    /* --cn-global--content-grid--columnsize: var(--cn-global--content-grid--xl--columnsize); */
    --cn-global--content-grid--guttersize: var(--cn-global--content-grid--xl--guttersize);
    --cn-global--content-grid--offsetsize: var(--cn-global--content-grid--xl--offsetsize);
  }
}

/* xxl: 1440px */
@media all and (min-width: map.get($grid-breakpoints, xxl)) {
  :root {
    /* --cn-global--content-grid--columnsize: var(--cn-global--content-grid--xxl--columnsize); */
    --cn-global--content-grid--guttersize: var(--cn-global--content-grid--xxl--guttersize);
    --cn-global--content-grid--offsetsize: var(--cn-global--content-grid--xxl--offsetsize);
  }
}

