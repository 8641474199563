/** ***********************************************************************************
 * Conchiglia miscellaneous design tokens
 *********************************************************************************** */

:root {

  /**
   * Durations
   */

  --cn-global--Duration--tn: 100ms;
  --cn-global--Duration--xs: 150ms;
  --cn-global--Duration--sm: 300ms;
  --cn-global--Duration--md: 800ms;
  --cn-global--Duration--lg: 1400ms;
  --cn-global--Duration--xl: 1800ms;


  /**
   * Timing functions
   */

  --cn-global--AnimationTimingFunction--bounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --cn-global--AnimationTimingFunction--easeoutcubic: cubic-bezier(0.33, 1, 0.68, 1);


  /**
   * Box shadow
   */

  --cn-global--BoxShadow--default: 0 2px 10px 0 rgba(0, 0, 0, .12);
  --cn-global--BoxShadow--CardButton: 0 0 10px rgba(0, 0, 0, .13);
}
