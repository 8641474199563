/** ***************************************************************************
 * Conchiglia utility classes
 *************************************************************************** */

/**
 * Hidden
 */

.cn-u-hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute !important;
}

.cn-u-hidden_focusable {
  clip: rect(0 0 0 0);
  border: none;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.cn-u-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/**
 * Force visibility
 */

.make-visible {
  visibility: visible !important;

  > * {
    opacity: 1 !important;
  }
}


/**
 * Layout
 */

.cn-u-center-absolute {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* apply to children of element with template columns */
.cn-u-subgrid_alternative {
  /* span the whole grid */
  grid-column: 1 / -1;

  /* create another grid and inherit the values from the parent grid */
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;

  /* overwrite the display for browsers that usnderstand display: contents */
  /* display: contents; */
}

input:focus-visible + label.cn-c-button {
  outline: -webkit-focus-ring-color auto 1px;
}


.cn-u-divider {
  width: 100%;
  height: 1px;
  background-color: var(--cn-global--border--Color--DarkTheme);

  &.dark-background {
    background-color: var(--cn-global--border--Color);
  }
}

.cn-u-error_text {
  font: var(--cn-global--Font-label--sm-regular);
  margin-block: pxToRem(14px) 0;
  color: var(--cn-global--palette-red);

  &.general {
    margin-block: pxToRem(20px);
  }
}
