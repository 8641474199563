/** ***********************************************************************************
 * Conchiglia common Shared template styles
 *********************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

body {
  --cn-c-template_shared--top_nav--OffsetHeight: 0px;
  --cn-c-template_shared--top_nav--Height: #{pxToRem(58px)};
  --cn-c-template_shared--top_nav--PositionY: calc(var(--cn-c-template_shared--top_nav--OffsetHeight) + var(--cn-c-template_shared--top_nav--Height));
  --cn-c-template_shared--main_nav--Height: #{pxToRem(97px)};
  --cn-c-template_shared--main_nav--MinHeight: #{pxToRem(73px)};
  --cn-c-template_shared--content--MaxWidth: #{map.get($grid-breakpoints, xxxl)};
}

.cn-c-cursor {
  display: none;

  &:has(.cursor-shape.cursor--backdrop),
  &:has(.cursor-shape.cursor--index-backdrop) {
    @media all and not (pointer:coarse) {
      display: block;
    }
  }
}

.cn-c-header {
  transition: all var(--cn-global--Duration--md) cubic-bezier(.165,.84,.44,1)
}

.top_nav-container {

  .cn-c-top_nav {
    padding-inline: var(--cn-global--content-grid--offsetsize);
    height: var(--cn-c-template_shared--top_nav--PositionY);
    max-width: var(--cn-c-template_shared--content--MaxWidth);
    margin-inline: auto;
    padding-block: .625rem;

    .cn-c-navigation {
      --cn-c-navigation--MarginBlock: var(--cn-c-template_shared--top_nav--PositionY);
    }

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      .nav-expand-content {
        > div {
          max-width: var(--cn-c-template_shared--content--MaxWidth);
          margin-inline: auto;
        }
      }
    }
  }
}

.cn-c-header > .main_nav-container {
  background-color: var(--cn-c-header--Color);

  .cn-c-main_nav {
    padding-inline: var(--cn-global--content-grid--offsetsize);
    height: var(--cn-c-template_shared--main_nav--Height);
    max-width: var(--cn-c-template_shared--content--MaxWidth);
    margin-inline: auto;
    padding-block-start: pxToRem(15px);
  }
}

.cn-c-header.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: var(--cn-global--BoxShadow--default);
  z-index: 100;

  .cn-c-main_nav {
    height: var(--cn-c-template_shared--main_nav--MinHeight);
    padding-block-start: unset;
  }

  &.is-open {
    position: unset;
  }

  /* add space for fixed header when it's fixed to top */
  ~ .margin-to-set {
    margin-block-start: var(--cn-c-template_shared--top_nav--PositionY);

    @include dispatchToContextBreakpoints($grid-breakpoints) {
      margin-block-start: calc(var(--cn-c-template_shared--top_nav--PositionY) + var(--cn-c-template_shared--main_nav--Height));
    }
  }
}

.cn-c-search_bar {
  .container {
    max-width: var(--cn-c-template_shared--content--MaxWidth);
    margin-inline: auto;
    padding-inline: var(--cn-global--content-grid--offsetsize);
  }
}

.cn-c-footer {
  .footer-container {
    display: grid;
    grid-template-columns: var(--cn-c-page--Grid-Template);
    grid-gap: 0 var(--cn-c-page--Grid-Gap);
  }
}
