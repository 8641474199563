/**
 * PP Neue Montreal font definition
 * see https://pangrampangram.com/products/neue-montreal
 */

@font-face {
  font-family: "PPNeueMontreal";
  src: url("~/assets/fonts/PPNeueMontreal/PPNeueMontreal-Variable.woff2") format("woff2-variations");
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
}

@font-face {
  font-family: "PPNeueMontreal Book";
  src: url("~/assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PPNeueMontreal Book Italic";
  src: url("~/assets/fonts/PPNeueMontreal/PPNeueMontreal-BookItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
