@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-bold-italic-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-bold-italic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-bold-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-book-italic-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-book-italic-webfont.woff") format("woff");
  font-weight: 375;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-book-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-book-webfont.woff") format("woff");
  font-weight: 375;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-heavy-italic-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-heavy-italic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-heavy-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-heavy-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-italic-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-italic-webfont.woff") format("woff");
  font-weight: 450;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-light-italic-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-light-italic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-light-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-medium-italic-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-medium-italic-webfont.woff") format("woff");
  font-weight: 530;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-medium-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-medium-webfont.woff") format("woff");
  font-weight: 530;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker pro text";
  src: url("~/assets/fonts/blacker-pro-text/blacker-pro-text-regular-webfont.woff2") format("woff2"),
       url("~/assets/fonts/blacker-pro-text/blacker-pro-text-regular-webfont.woff") format("woff");
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}
