/** ***********************************************************************************
 * Typography design tokens
 *********************************************************************************** */

 @use "../fonts/PPNeueMontreal.scss";
 @use "../fonts/EuclidCircularB.scss";
 @use "../utilities.scss" as *;
 @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");
 @import url("../fonts/blacker_pro_text.css");

:root {

  /**
    * @tokens Font families
    * @presenter FontFamily
    */

  --cn-global--FontFamily-sans: "PPNeueMontreal", "Poppins", sans-serif;
  --cn-global--FontFamily-book: "PPNeueMontreal Book", "Poppins", sans-serif;
  --cn-global--FontFamily-serif: "Blacker pro text", "Noto Serif", serif;
  --cn-global--FontFamily-default: var(--cn-global--FontFamily-sans);


  /**
    * @tokens Font weights
    * @presenter FontWeight
    */

  --cn-global--FontWeight-thin:         100;  /* Thin */
  --cn-global--FontWeight-light:        300;  /* Light */
  --cn-global--FontWeight-book-regular: 375;  /* Book Regular */
  --cn-global--FontWeight-regular:      450;  /* Regular */
  --cn-global--FontWeight-medium:       530;  /* Medium */
  --cn-global--FontWeight-semibold:     700;  /* Semibold */
  --cn-global--FontWeight-bold:         800;  /* Bold */
  --cn-global--FontWeight-heavy:        900;  /* Heavy */


  /**
    * @tokens Article font sizes
    * @presenter FontSize
    */

  --cn-global--FontSize-label--xxs: #{pxToRem(10px)};
  --cn-global--FontSize-label--xs:  #{pxToRem(14px)};
  --cn-global--FontSize-label--sm:  #{pxToRem(16px)};
  --cn-global--FontSize-label--md:  #{pxToRem(18px)};
  --cn-global--FontSize-label--lg:  #{pxToRem(23px)};
  --cn-global--FontSize-label--xl:  #{pxToRem(30px)};

  --cn-global--FontSize-paragraph--sm:  #{pxToRem(18px)};
  --cn-global--FontSize-paragraph--md:  #{pxToRem(26px)};

  --cn-global--FontSize-title--md:  #{pxToRem(25px)};
  --cn-global--FontSize-title--2xl: #{pxToRem(50px)};
  --cn-global--FontSize-title--3xl: #{pxToRem(70px)};


  /**
    * @tokens Text styles
    * https://developer.mozilla.org/en-US/docs/Web/CSS/font#formal_syntax
    */

  --cn-global--Font-label--xxs-medium: var(--cn-global--FontWeight-medium) var(--cn-global--FontSize-label--xxs) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xs-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-label--xs) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xs-book: var(--cn-global--FontWeight-book-regular) var(--cn-global--FontSize-label--xs) var(--cn-global--FontFamily-book);
  --cn-global--Font-label--xs-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--xs) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--sm-book: var(--cn-global--FontWeight-book-regular) var(--cn-global--FontSize-label--sm) var(--cn-global--FontFamily-book);
  --cn-global--Font-label--sm-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-label--sm)/var(--cn-global--LineHeight-sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--sm-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-light: var(--cn-global--FontWeight-light) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-medium: var(--cn-global--FontWeight-medium) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--md-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--lg-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--lg) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xl-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-label--xl) var(--cn-global--FontFamily-default);
  --cn-global--Font-label--xl-bold: var(--cn-global--FontWeight-bold) var(--cn-global--FontSize-label--xl) var(--cn-global--FontFamily-default);

  --cn-global--Font-paragraph--sm-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-paragraph--sm) var(--cn-global--FontFamily-default);
  --cn-global--Font-paragraph--md-regular: var(--cn-global--FontWeight-regular) var(--cn-global--FontSize-paragraph--md) var(--cn-global--FontFamily-default);

  --cn-global--Font-title--md-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--md) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--2xl-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--2xl) var(--cn-global--FontFamily-default);
  --cn-global--Font-title--3xl-semibold: var(--cn-global--FontWeight-semibold) var(--cn-global--FontSize-title--3xl) var(--cn-global--FontFamily-default);


  /**
    * @tokens Line heights
    * @presenter LineHeight
    */

  --cn-global--LineHeight-sm: 1.375;  /* Small (paragraph) */
  --cn-global--LineHeight-md: 1.45;  /* Medium (label) */
  --cn-global--LineHeight-2xl: #{pxToRem(74px)};

}
