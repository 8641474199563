/**
 * Euclid Circular B font definition
 * see https://www.swisstypefaces.com/fonts/euclid/#Circular%20B
 */

@font-face {
  font-family: "EuclidCircularB";
  src: url("~/assets/fonts/nodi/EuclidCircularB-Medium-WebS.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
