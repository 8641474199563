/** ***********************************************************************************
 * Conchiglia common E-commerce template styles
 *********************************************************************************** */

@use "../utilities.scss" as *;

:root {
  --cn-c-page--Font: var(--cn-global--Font-paragraph--sm-regular);

  /**
   * Default color palette
   */

  --cn-c-page--AccentColor: var(--cn-global--palette-red);
  --cn-c-page--AccentColor--with-opacity: var(--cn-global--palette-red--65);
  --cn-c-page--BackgroundColor: var(--cn-global--palette-white);
  --cn-c-page--Color: var(--cn-global--palette-black);
}



/**
 * Page
 * 1: reset
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

body {
  --cn-c-page--Grid-Template: var(--cn-global--content-grid--GridTemplateColumns);
  --cn-c-page--Grid-Gap: var(--cn-global--content-grid--guttersize);
}

body {
  accent-color: var(--cn-c-page--AccentColor);
  background-color: var(--cn-c-page--BackgroundColor);
  color: var(--cn-c-page--Color);
  font: var(--cn-c-page--Font);
  height: 100%;
}



/**
 * Label
 */

label:has(input) {
  cursor: pointer;
}

label[for] {
  cursor: pointer;
}



.sticky-highlight {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, .6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 5rem;
  /* left: 0; */
  max-width: 100% !important;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: none;
  transform: translateY(-200%) !important;
  transition: all var(--cn-global--Duration--md) ease;
  visibility: hidden;
  z-index: 9;
}

.sticky-highlight.scroll-up {
  transform: translateY(0) !important;
  visibility: visible;
}

.cn-c-footer {
  --cn-c-footer--PaddingBlock: #{pxToRem(16px)};
  --cn-c-footer--PaddingInline: var(--cn-global--content-grid--offsetsize);
  --cn-c-footer--MaxWidth: var(--cn-c-home--content--MaxWidth, var(--cn-c-template_shared--content--MaxWidth));
}
