/** ***********************************************************************************
 * Common color design tokens
 *********************************************************************************** */

:root {
  /**
   * @tokens Orphan colors
   * @presenter Color
   */

  /** White: #fff */
  --cn-global--palette-white--100: rgba(255, 255, 255, 1);  /* White / 100% */
  --cn-global--palette-white--80: rgba(255, 255, 255, .8);  /* White / 80% */
  --cn-global--palette-white--73: rgba(255, 255, 255, .73);  /* White / 73% */
  --cn-global--palette-white--60: rgba(255, 255, 255, .6);  /* White / 60% */
  --cn-global--palette-white--30: rgba(255, 255, 255, .3);  /* White / 30% */
  --cn-global--palette-white: var(--cn-global--palette-white--100);

  /** Red: #e61425 */
  --cn-global--palette-red--100: rgba(230, 20, 37, 1);  /* Red / 100% */
  --cn-global--palette-red--80: rgba(230, 20, 37, .8);  /* Red / 80% */
  --cn-global--palette-red--65: rgba(230, 20, 37, .65);  /* Red / 65% */
  --cn-global--palette-red: var(--cn-global--palette-red--100);


  /**
   * @tokens Primary colors
   * @presenter Color
   */

  /** Black: #282828 */
  --cn-global--palette-black--03: rgba(40, 40, 40, .03);  /* Black / 3% */
  --cn-global--palette-black--10: rgba(40, 40, 40, .1);  /* Black / 10% */
  --cn-global--palette-black--15: rgba(40, 40, 40, .15);  /* Black / 15% */
  --cn-global--palette-black--25: rgba(40, 40, 40, .25);  /* Black / 25% */
  --cn-global--palette-black--30: rgba(40, 40, 40, .3);  /* Black / 30% */
  --cn-global--palette-black--40: rgba(40, 40, 40, .4);  /* Black / 40% */
  --cn-global--palette-black--50: rgba(40, 40, 40, .5);  /* Black / 50% */
  --cn-global--palette-black--60: rgba(40, 40, 40, .6);  /* Black / 60% */
  --cn-global--palette-black--67: rgba(40, 40, 40, .67);  /* Black / 60% */
  --cn-global--palette-black--70: rgba(40, 40, 40, .7);  /* Black / 70% */
  --cn-global--palette-black--80: rgba(40, 40, 40, .8);  /* Black / 80% */
  --cn-global--palette-black--100: rgba(40, 40, 40, 1);  /* Black / 100% */
  --cn-global--palette-black: var(--cn-global--palette-black--100);

  /** Dark gray: #2f2f2f */
  --cn-global--palette-dark-gray--100: rgba(47, 47, 47, 1);  /* Dark gray / 100% */
  --cn-global--palette-dark-gray--60: rgba(47, 47, 47, .6);  /* Dark gray / 60% */
  --cn-global--palette-dark-gray: var(--cn-global--palette-dark-gray--100);



  /** SPANISH GREY: #979797 */
  --cn-global--palette-spanish-grey--100: rgba(151, 151, 151, 1);
  --cn-global--palette-spanish-grey--30: rgba(151, 151, 151, .3);
  --cn-global--palette-spanish-grey: var(--cn-global--palette-spanish-grey--100);

  /** RED PIGMENT: #ed1d24 */
  --cn-global--palette-red-pigment--100: rgba(237, 29, 36, 1);
  --cn-global--palette-red-pigment: var(--cn-global--palette-red-pigment--100);

  /** White Smoke: #f5f5f5 */
  --cn-global--palette-white-smoke--100: rgba(245, 245, 245, 1);
  --cn-global--palette-white-smoke: var(--cn-global--palette-white-smoke--100);

  /** Unbleached: #fbf9f7 */
  --cn-global--palette-unbleached--100: rgba(251, 249, 247, 1);
  --cn-global--palette-unbleached: var(--cn-global--palette-unbleached--100);


  /** Borders */
  --cn-global--border--Color: var(--cn-global--palette-black--30);
  --cn-global--border--Color--DarkTheme: var(--cn-global--palette-white--30);
  --cn-global--border: 1px solid var(--cn-global--border--Color);
  --cn-global--border--DarkTheme: 1px solid var(--cn-global--border--Color--DarkTheme);
}
